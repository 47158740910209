import React from 'react'
import {Component} from 'react'
import './App.css';

export class App extends Component {
  constructor (props) {
    super(props);
  }

  render() {
    return(
      <div>
      </div>
    )
  }
}

export default App
